import { graphql, Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import SEO from '../components/SEO';

const NewsStyles = styled.div`
  max-width: 800px;
  margin-bottom: 10rem;

  h2 {
    margin: 2rem 0 1rem;
    font-size: 1.75rem;
    color: var(--black);
    font-family: 'EB Garamond', serif;
  }
`;

export default function News(props) {
  const { data = {} } = props;
  const newsChildren = data.news.nodes || [];
  return (
    <NewsStyles>
      <SEO title="Community and News" />
      <h1>Community &amp; News</h1>
      {newsChildren.map(
        (news) =>
          news &&
          news.slug &&
          news.slug.current && (
            <h2 key={news.slug.current}>
              {news.name && <Link to={news.slug.current}>{news.name}</Link>}
            </h2>
          )
      )}
    </NewsStyles>
  );
}

export const query = graphql`
  query NewsQuery {
    # renames query
    news: allSanityNewspage {
      nodes {
        name
        slug {
          current
        }
      }
    }
  }
`;
